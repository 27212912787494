var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.getIsRowClosedLocal)?_c('div',{ref:"expensesRowRef",staticClass:"expenses__row",class:{
    'expenses__row--add-new-task': _vm.isAddNewTaskRow,
    'expenses__row--non-editable': _vm.isNonEditable,
    'expenses__row--hidden': _vm.isRowHidden,
  },style:({
    'grid-template-columns': `${_vm.columnWidht}px repeat(${
      _vm.getSelectedInterval.amount + 1
    }, 50px)`,
  })},[_vm._t("default")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }