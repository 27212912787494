<template>
  <div
    v-if="!getIsRowClosedLocal"
    ref="expensesRowRef"
    class="expenses__row"
    :style="{
      'grid-template-columns': `${columnWidht}px repeat(${
        getSelectedInterval.amount + 1
      }, 50px)`,
    }"
    :class="{
      'expenses__row--add-new-task': isAddNewTaskRow,
      'expenses__row--non-editable': isNonEditable,
      'expenses__row--hidden': isRowHidden,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { getLocalStorage } from "@/assets/utils";

export default {
  name: "ExpensesRow",
  data() {
    return {
      isFocused: false,
      isClosed: this.isRowClosed(),
    };
  },
  props: {
    isAddNewTaskRow: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isNonEditable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    parentSpoilerName: {
      type: String,
      default() {
        return "";
      },
    },
    isRowHidden: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    isRowClosed() {
      if (!this.parentSpoilerName) {
        return false;
      }

      let closedSpoilerIds = [];
      if (this.getReadonly) {
        closedSpoilerIds = this.getClosedRows;
      } else {
        closedSpoilerIds =
          JSON.parse(getLocalStorage("CLOSED_SPOILER_IDS")) || [];
      }

      return closedSpoilerIds?.find((closedSpoilerId) =>
        this.parentSpoilerName.startsWith(closedSpoilerId)
      );
    },
  },
  computed: {
    ...mapState({
      firstColumnWidth: (state) => state.expensesStore.firstColumnWidth,
      readonly: (state) => state.expensesStore.readonly,
    }),
    ...mapGetters({
      getSelectedInterval: "expensesStore/getSelectedInterval",
      getReadonly: "expensesStore/getReadonly",
      getClosedRows: "expensesStore/getClosedRows",
    }),
    columnWidht() {
      return this.readonly ? 300 : this.firstColumnWidth;
    },
    getIsRowClosedLocal() {
      return this.isClosed;
    },
  },
  watch: {
    getClosedRows: {
      handler() {
        this.isClosed = this.isRowClosed();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.expenses__row {
  background-color: var(--editable-bg-color);
  color: #2c3e50;
  text-align: center;
  font-weight: 400;
  display: grid;
  position: relative;
  transition: color 0.2s;
}

.expenses__row--add-new-task {
  background-color: #f3f3f3;
}

.expenses__row--non-editable > div {
  background-color: #ccc;
}

.expenses__row--hidden {
  color: #a0a7ae;
}
</style>
